.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.active{
  color: #BFA219 !important;
}

body{
  background-color: rgb(250, 251, 251);
}

/* .spring-green{
  color: aquamarine !important;
}

.bg-spring-green{
  background-color: rgb(72, 111, 98) !important;
} */


lottie-player{
  margin: 0 auto;
  width: 50%;
  height: 50%;
}

.alert-danger{
  color: #BFA219 !important;
}
.alert{
  background-color: #BFA219 !important;
}

/* Le footer en bas de la page */
#root{
  position: relative;
  min-height: 100vh;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.5rem;  }          /* Hauteur du pied-de-page */

